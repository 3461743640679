@mixin xs {
  @media (max-width: $xs-max) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $sm-min) and (max-width: $sm-max) {
    @content;
  }
}
@mixin sm-min {
  @media (min-width: $sm-min) {
    @content;
  }
}
@mixin sm-max {
  @media (max-width: $sm-max) {
    @content;
  }
}

@mixin md {
  @media (min-width: $md-min) and (max-width: $md-max) {
    @content;
  }
}
@mixin md-min {
  @media (min-width: $md-min) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: $md-max) {
    @content;
  }
}

@mixin lg-min {
  @media (min-width: $lg-min) {
    @content;
  }
}
@mixin lg {
  @media (min-width: $lg-min) and (max-width: $lg-max) {
    @content;
  }
}
@mixin lg-max {
  @media (max-width: $lg-max) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: $xxl) {
    @content;
  }
}

@mixin between($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}
