.profile {
  &__avatar {
    content: " ";
    display: inline-block;
    position: relative;

    &:after {
      content: "Preview";
      color: $color-black;
      background: rgba($color-white, 0.3);
      bottom: 0;
      font-size: 10px;
      left: 0;
      padding: 5px;
      position: absolute;
    }
  }
}
