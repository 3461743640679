.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -10px;
  overflow: hidden;
  padding: 0;

  &__item {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0 10px;
    width: 50%;

    @include md-max {
      flex: 0 0 100%;
      width: 100%;
    }

    &.Owner,
    &.Admin,
    &.Active {
      .cards__tag {
        background: #28a745;
      }
    }

    &.Inactive {
      .cards__tag {
        background: #eb4d4b;
      }
    }
  }

  &__inner {
    background: $color-white;
    border: 1px solid $color-border;
    border-bottom: 3px solid $color-border;
    font-size: 14px;
    height: 100%;
    min-height: 75px;
    overflow: hidden;
    padding: 10px;

    &:hover {
      border-bottom-color: $color-brand;
      color: $color-brand;
      text-decoration: none;
    }
  }

  &__innerWrap {
    display: flex;
    min-height: 100%;
    position: relative;
    padding-bottom: 15px;
  }

  &__details {
    color: $color-black;
  }

  &__desc {
    font-size: 12px;
    font-style: italic;
    color: lighten($color-black, 50);
  }

  &__logo {
    flex: 0 0 32px;
    height: 32px;
    width: 32px;
    margin-right: 10px;
    background : {
      size: cover;
      repeat: no-repeat;
      position: center;
    }
  }

  &__tag {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $color-brand;
    color: $color-white;
    display: inline-block;
    border-radius: 3px;
    font-size: 11px;
    padding: 3px 6px;
  }

  &--people {
    .cards__item {
      flex: 0 0 100%;
      width: 100%;

      @include lg-min {
        flex: 0 0 25%;
        width: 25%;
      }

      @include sm {
        flex: 0 0 50%;
        width: 50%;
      }

      @include md {
        flex: 0 0 33.33%;
        width: 33.33%;
      }
    }

    .cards__inner {
      word-wrap: break-word;

      &:hover {
        border-bottom-color: $color-border;
        color: $color-black;
      }
    }

    .cards__innerWrap {
      padding-bottom: 30px;
    }
  }
}
