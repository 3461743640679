//Breakpoint Sizes
$xxl: 1400px;
$xl: 1201px;
$lg-max: (
    $xl
);
$lg-min: 992px;
$md-max: (
    $lg-min
);
$md-min: 768px;
$sm-max: (
    $md-min
);
$sm-min: 576px;
$xs-max: (
    $sm-min
);

//Colours
$color-brand: #007cc2;
$color-border: #d1d5da;
$color-brandAlt: #22a6b3;
$color-white: #fff;
$color-black: #000;
$color-grey: #E0E0E0;