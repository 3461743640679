.navDrawerNew {
    display: none;
    padding: 5px 0px 5px 0px;

    button {
        padding: 10px 0px 10px 0px;

        &:hover {
            background: none;
        }
    }

    @include xs {
        display: block;
    }

    @include sm {
        display: block;
    }

    &__link {
        text-decoration: none;
        color: $color-black;

        &:hover {
            text-decoration: none;
            color: $color-black;
        }
    }
}