@keyframes Wave {
  0% {
    transform: rotate(12deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(12deg);
  }
}
.signOut {
  align-items: center;
  // background: $color-white;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 100;

  &__wave {
    margin: 0 0 10px;

    img {
      animation: Wave 1s infinite;
      transform-origin: 50% 100%;
      display: inline-block;
    }
  }
}
