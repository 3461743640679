.accountNav {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-left: auto;

  &__signOut {
    color: $color-white;
    margin-left: 10px;

    &:before {
      content: " ";
      border-left: 1px solid $color-white;
      padding-left: 10px;
    }
  }

  &__avatar {
    align-items: center;
    background: rgba($color-brand, 0.2);
    border: 1px solid $color-brand;
    border-radius: 4px;

    color: $color-white;
    display: flex;
    padding: 5px;

    &:hover {
      background: rgba($color-white, 0.4);
      text-decoration: none;
    }

    img {
      height: auto;
      max-height: 30px;
      width: auto;

      @include sm-min {
        margin-right: 5px;
      }
    }
  }

  &__name {
    @include xs {
      display: none;
    }
  }
}
