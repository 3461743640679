.errorPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  &__img {
    border-radius: 50%;
    box-shadow: 0px 0px 15px 0px rgba($color-black, 0.2);
    height: 180px;
    overflow: hidden;
    margin: 0 0 30px 0;
    width: 180px;
  }
}
