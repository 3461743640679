@mixin hideText() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin bgCover($source: "") {
  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
  }

  @if ($source) {
    background-image: url($source);
  }
}

@mixin bgContain($source) {
  background: {
    image: url($source);
    position: center center;
    repeat: no-repeat;
    size: contain;
  }
}

@mixin closeBtn($color: $color-brand) {
  cursor: pointer;
  height: 40px;
  position: relative;
  width: 40px;
  z-index: 10;

  @include md-min {
    height: 20px;
    width: 20px;
  }

  &:before,
  &:after {
    background-color: rgba($color, 1);
    content: " ";
    display: block;
    height: 1px;
    left: 10px;
    top: 20px;
    position: absolute;
    transform: rotateZ(-45deg);
    transition: 150ms background linear;
    width: 20px;

    @include md-min {
      left: 0px;
      top: 10px;
    }
  }

  &:after {
    transform: rotateZ(45deg);
  }

  &:hover {
    &:before,
    &:after {
      background-color: rgba($color, 0.75);
    }
  }
}
