@keyframes DanceBg {
  0% {
    background: #f9ca24;
  }
  20% {
    background: #30336b;
  }

  40% {
    background: #95afc0;
  }

  60% {
    background: #ffbe76;
  }

  80% {
    background: #eb4d4b;
  }
}

@keyframes Dance {
  from,
  49% {
    transform-origin: -50% 100%;
  }
  50%,
  75%,
  to {
    transform-origin: 150% 100%;
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(10deg);
  }
}

.iMustDance {
  background: $color-white;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  //   animation: DanceBg 10s infinite alternate;

  &__dancerWrap {
    position: absolute;
  }

  &__dancer {
    animation: Dance 0.8s infinite alternate ease-in-out;
  }

  > .h6 {
    text-align: center;
    top: 50%;
    position: absolute;
    width: 100%;
  }
}
