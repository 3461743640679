.accountNavNew {
  align-items: center;
  display: flex;
  font-size: 14px;
  //margin-left: auto;

  &__signOut {
    color: $color-white;
    margin-left: 10px;
    border: 1px solid $color-grey;
    border-radius: 25px;
    padding: 6px 13px 6px 13px;

    &:hover {
      border: 1px solid $color-black;
      text-decoration: none;
    }

    @include xs {
      border: none;
      border-radius: none;
      padding: 0px;

      &:hover {
        border: none;
        text-decoration: none;
      }
    }

    @include sm {
      border: none;
      border-radius: none;
      padding: 0px;

      &:hover {
        border: none;
        text-decoration: none;
      }
    }

    img {
      height: auto;
      max-height: 23px;
      width: auto;
      margin-right: 5px;
    }
  }

  &__account {
    align-items: center;
    background: $color-grey;
    border: 1px solid $color-grey;
    border-radius: 25px;

    color: $color-white;
    display: flex;
    padding: 6px 13px 6px 13px;

    &:hover {
      background: rgba($color-white, 0.4);
      border: 1px solid $color-grey;
      text-decoration: none;
    }

    @include xs {
      border: none;
      border-radius: none;
      background: none;
      padding: 0px;

      &:hover {
        border: none;
        background: none;
        text-decoration: none;
      }
    }

    @include sm {
      border: none;
      border-radius: none;
      background: none;
      padding: 0px;

      &:hover {
        border: none;
        background: none;
        text-decoration: none;
      }
    }

    img {
      height: auto;
      max-height: 20px;
      width: auto;
      margin-right: 5px;
    }
  }

  &__name {
    @include xs {
      display: none;
    }

    @include sm {
      display: none;
    }
  }

  &__link {
    font-weight: 600;
    padding-right: 10px;

    @include xs {
      display: none;
    }

    @include sm {
      display: none;
    }
  }

  &__hiddenText {
    @include xs {
      display: none;
    }

    @include sm {
      display: none;
    }
  }
}