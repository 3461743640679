.siteHeader {
  align-items: center;
  background: $color-white;
  border-bottom: 1px solid $color-grey;
  color: $color-black;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  a {
    color: $color-black;
  }

  &__logo {
    display: block;
    //margin-right: 20px;

    @include xs {
      max-width: 50%;
      height: 100%;
      //justify-self: center;
    }

    @include sm {
      max-width: 50%;
      height: 100%;
      //justify-self: center;
    }

    @include sm-min {
      height: 100%;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__greeting {
    font-size: 14px;

    span {
      display: block;
      font-size: 10px;
    }
  }
}