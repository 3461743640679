.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid $color-border;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__tab {
    border-bottom: 2px solid transparent;
    color: $color-black;
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    top: 1px;

    &--selected {
      border-bottom: 2px solid $color-brand;
      font-weight: 700;
      color: $color-brand;
    }

    .btn + .btn {
      margin-left: 10px;
    }
  }

  &__tab-panel {
    border-bottom: 2px solid $color-brand;
    display: none;
    padding: 20px 0 50px;
    position: relative;

    &--selected {
      display: block;
    }
  }
}
