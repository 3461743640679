.avatar {
  margin-bottom: 20px;
  max-width: 100%;

  &__name {
    font-size: 26px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  &__email {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__img {
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
    }
  }
}
