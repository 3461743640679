.mediaLib {
  align-items: center;
  background: rgba($color-black, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  &--drag {
    .mediaLib__inner {
      overflow: hidden;
    }
  }

  &__close {
    @include closeBtn;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__header {
    align-items: center;
    display: flex;
    padding: 0 0 20px;
  }

  &__filters {
    // margin-left: auto;
    margin-left: 20px;
    font-size: 12px;

    .btn {
      margin-left: 10px;
    }
  }

  &__reqs {
    font-size: 12px;
    margin: 0 0 30px;
  }

  &__inner {
    position: relative;
    border-radius: 5px;
    background: $color-white;
    max-height: 80%;
    max-width: 80%;
    overflow-y: auto;
    width: 800px;
  }

  &__content {
    min-height: 100px;
    max-height: 100%;
    padding: 20px;
  }

  &__scroll {
    position: relative;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    cursor: pointer;
    flex: 0 0 25%;
    padding: 25% 0 0;
    position: relative;

    &.Loading,
    &.Error,
    &.Processing {
      cursor: not-allowed;
    }

    &.Ready,
    &.Processed {
      .mediaLib__itemContent {
        opacity: 1;
      }
    }
  }

  &__hiddenImgForLoading {
    height: 0;
    width: 0;
    opacity: 0;
  }

  &__status {
    background: {
      color: $color-white;
      repeat: no-repeat;
      position: center;
      size: contain;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    justify-content: center;

    span {
      display: block;
      opacity: 0.8;
    }
  }

  &__itemContent {
    background: {
      color: $color-white;
      repeat: no-repeat;
      position: center;
      size: contain;
    }
    border: 5px solid $color-white;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 300ms opacity linear;
    width: 100%;
    height: 100%;

    &:hover {
      &:after {
        background-color: rgba($color-black, 0.1);
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__loading {
    align-items: center;
    background: rgba($color-white, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;

    .loading__img {
      height: 128px;
      width: 128px;
    }
  }

  &__dragging {
    align-items: center;
    background: rgba($color-white, 0.9);
    border: 10px dashed $color-brand;
    display: flex;
    height: 100%;
    font-weight: 700;
    justify-content: center;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
