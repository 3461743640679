.sk {
  animation: shine 4s infinite linear;
  background-color: #f2f2f2;
  border: 1px solid transparent;
  margin: 1px;
  display: block;
  background-image: linear-gradient(90deg, #f2f2f2 0px, #eaeaea 40px, #f2f2f2 60px);

  &:after {
    content: "-";
    opacity: 0;
  }

  &--image {
    width: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .sk__inner {
      display: none;
    }
  }

  &--inline {
    display: inline-block;
  }

  &--btn {
    height: 38px;
    width: 100px;
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 500px;
  }
}
