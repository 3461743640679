.pageHeader {
  background: $color-brand;
  color: $color-white;
  padding: 50px 0;
  position: relative;
  margin: -20px 0 20px;

  &__content {
    align-items: center;
    display: flex;

    @include sm {
      flex-wrap: wrap;
    }
  }

  &__main {
    align-items: center;
    display: flex;
    flex: 1 1 100%;

    @include sm {
      flex-shrink: 0;
    }

    a {
      color: $color-white;
    }
  }

  &__secondary {
    flex: auto;
    margin-left: auto;

    @include sm {
      padding-top: 30px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__logo {
    height: 64px;
    margin-right: 20px;
    width: auto;

    @include xs {
      display: none;
    }
  }

  &__breadcrumb {
    font-size: 14px;

    @include xs {
      font-size: 12px;
    }
  }

  &__sep {
    margin: 0 3px;
  }

  .sk {
    opacity: 0.2;
    min-width: 300px;
  }
}
