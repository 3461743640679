.timer {
  display: flex;
  align-items: center;
  justify-content: center;

  &__step {
    margin: 0 10px;
    text-align: center;
    flex: 0 0 50px;
  }

  &__count {
    background: $color-brand;
    color: white;
    padding: 10px;
    font-size: 26px;
  }

  &__label {
    display: block;
    font-size: 12px;
  }

  &__message {
    text-align: center;
    font-style: italic;
    padding: 0 0 10px;
  }
}

.pageHeader {
  .timer {
    &__count {
      background: $color-white;
      color: $color-brand;
    }

    &__step {
      margin: 0 5px;
      text-align: center;
      flex: 0 0 50px;
    }
  }
}
