.faqListNew {

    &__heading {
        padding: 10px 0px 10px 0px;
        font-weight: 700;
        font-size: 24px;
    }

    &__accordion {
        padding-bottom: 20px;
    }

    &__question {
        font-size: 15px;
        font-weight: 600;
    }

    &__answer {
        font-size: 15px;
    }

    &__logo {
        display: inline-block;
        align-items: center;

        img {
            width: 100%;
            padding-top: 20px;

            @include xs {
                max-height: 200px;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            @include sm {
                max-height: 200px;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            @include md {
                max-height: 200px;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }
}