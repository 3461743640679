.orgList {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__listItem {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;
  }

  &__itemLogo {
    height: auto;
    margin-right: 5px;
    width: 16px;
  }
}
