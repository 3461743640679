.invites {
  margin: 0;
  padding: 0;
  list-style: none;

  &__invite {
    display: flex;
    justify-content: space-between;
    margin: 0 0 5px;
    padding: 0;
  }

  &__type {
    font-style: italic;
    font-size: 12px;
    margin-left: 10px;
  }
}
