.eventcardsnew {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  &__inner {
    width: 200px;

    &:hover {
      text-decoration: none;
      color: "inherit";
    }
  }

  &__innerWrap {
    color: $color-black;
    width: inherit;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border-radius: 20px 20px 20px 20px;
    transition: all 0.3s ease-in-out;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

    &:hover {
      background-color: rgba(209, 209, 209, 0.95);
    }

    &:hover .eventcardsnew__logo {
      opacity: 0.95;
    }
  }

  &__logo {
    height: 115px;
    width: 200px;
    border-radius: 20px 20px 0px 0px;

    background : {
      size: cover;
      repeat: no-repeat;
      position: center;
    }
  }

  &__content {
    height: 80px;
    display: flex;
    flex-direction: column;
    background-color: inherit;
    border-radius: 0px 0px 20px 20px;
    padding: 5px 10px 10px 10px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}