@keyframes loader {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.loading {
  text-align: center;

  &--fs {
    background: rgba($color-black, 0.8);
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  &__img {
    animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 0px rgba($color-black, 0.3);
    height: 150px;
    overflow: hidden;
    width: 150px;
  }

  &--small {
    .loading__img {
      height: 100px;
      width: 100px;
    }
  }
}
