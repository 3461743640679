a {
  color: $color-brand;

  &:hover {
    color: $color-brandAlt;
  }
}

.btnList {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 5px 5px 5px 0;

    a {
      @extend .btn;
      @extend .btn-light;
    }
  }
}
