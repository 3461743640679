.notifications {
  top: 70px;
  position: fixed;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 999;

  .container {
    position: relative;
    // overflow: hidden;
  }

  .alert {
    top: 0;
    border-radius: 0;
    left: 0;
    font-size: 12px;
    padding: 20px;
    margin: 0 !important;
    position: absolute;
    transition: 100ms bottom ease-out;
    width: 100%;
  }
}
