.videoPlayer {
  align-items: center;
  background: rgba($color-black, 0.9);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  &__close {
    @include closeBtn($color-white);
    margin-left: 50%;
    left: -10px;
    top: -10px;
  }

  video {
    background: $color-black;
  }
}
