.scores {
  margin: 50px auto 0;
  max-width: 800px;

  &__winners {
    color: $color-brand;
    text-align: center;
    padding: 50px 0 0;
  }

  &__row {
    align-items: center;
    display: flex;
    border-bottom: 1px solid $color-border;
    padding: 5px 0;

    &:nth-child(even) {
      background: rgba(0, 0, 0, 0.01);
    }

    &--1 {
      padding: 10px 0;

      .scores__team {
        font-size: 35px;
      }

      .scores__count {
        font-size: 60px;
      }

      .scores__medal {
        height: 50px;
      }
    }

    &--2 {
      padding: 10px 0;

      .scores__team {
        font-size: 30px;
      }

      .scores__count {
        font-size: 50px;
      }

      .scores__medal {
        height: 44px;
      }
    }

    &--3 {
      padding: 10px 0;
      .scores__team {
        font-size: 25px;
      }

      .scores__count {
        font-size: 40px;
      }

      .scores__medal {
        height: 40px;
      }
    }
  }

  &__place {
    text-align: center;
    // font-size: 30px;
    flex: 0 0 60px;
  }

  &__medal {
    flex: 0 0 32px;
    height: 32px;
    overflow: hidden;
    width: 100%;

    background : {
      size: contain;
      repeat: no-repeat;
      position: center;
    }
  }

  &__team {
    flex: 1 1 auto;

    font-size: 20px;
    text-align: left;
  }

  &__logo {
    border-radius: 50%;
    flex: 0 0 32px;
    height: 32px;
    overflow: hidden;
    width: 32px;
    margin-right: 10px;

    background : {
      size: cover;
      repeat: no-repeat;
      position: center;
    }
  }

  &__count {
    margin-left: auto;
    font-size: 30px;
    line-height: 1;
  }
}
