.projectCards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 10px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        /* make scrollbar transparent */
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &__item {
        width: inherit;
    }

    &__img {
        height: 100%;
        aspect-ratio: 1 / 1;
    }
}