.avatarNew {
  max-width: 100%;

  &__name {
    font-size: 20px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  &__email {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__img {
    overflow: hidden;
    width: 60%;
    padding-bottom: 5px;

    img {
      width: 100%;
      border-radius: 15%;
    }

    @include xs {
      width: 50px;
      padding-right: 10px;
    }

    @include sm {
      width: 50px;
      padding-right: 10px;
    }

    @include md {
      width: 50px;
      padding-right: 10px;
    }
  }

  @include xs {
    display: flex;
    flex-direction: row;
  }

  @include sm {
    display: flex;
    flex-direction: row;
  }

  @include md {
    display: flex;
    flex-direction: row;
  }
}