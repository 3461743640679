.dashboardVideoPlayerNew {
    margin-bottom: 10px;

    &__inner {
        max-height: 300px;

        @include md {
            max-height: 200px;
        }
    }
}