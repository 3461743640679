.inviteBar {
  background: #dfe6e9;
  border-top: 1px solid $color-white;
  position: relative;
  top: -20px;

  &__content {
    align-items: center;
    padding: 10px 0;
    display: flex;
    font-size: 14px;
    justify-content: center;
    // max-width: 500px;
    margin: 0 auto 20px;
  }
}
