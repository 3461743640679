body {
  padding-top: 70px;
}

.page {
  padding-top: 20px;
}

.md {
  img {
    max-width: 100%;
  }
}

.abs-r {
  position: absolute;
  right: 0;
}

.abs-l {
  position: absolute;
  left: 0;
}

.voteBtn {
  align-items: center;
  background: $color-brand;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  margin: 50px 0;
  width: 150px;
  height: 150px;
  position: relative;

  &:before {
    content: "Vote";
    display: block;
    font-weight: 700;
    position: absolute;
    bottom: 15px;
  }

  img {
    display: block;
    height: auto;
    width: 55%;
    position: absolute;
    transition: 200ms all ease-out;
    z-index: 2;
  }

  &__thumb {
    margin-top: -15px;
    opacity: 1;
    transform: scale(1);
  }

  &__check {
    opacity: 0;
    transform: scale(5);
  }

  &:hover:not(.voteBtn--voted) {
    background: rgba($color-brand, 0.8);

    .voteBtn__thumb {
      transform: scale(1.1);
    }
  }

  &--voted {
    background: #218838;

    &:before {
      content: "Voted!";
    }

    .voteBtn__check {
      opacity: 1;
      transform: scale(1);
    }

    .voteBtn__thumb {
      opacity: 0;
      transform: scale(5);
    }
  }
}

.newVoteBtn {
  background: $color-brand;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 700;
  padding: 30px;
  font-size: 40px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &--voted {
    background: #218838;
  }

  &:hover:before {
    content: " ";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
  }
}
